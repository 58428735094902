$(document).ready(function() {

	for ( var i = 1; i < $('.cbox-content').length + 1; i++ ) {
		$(".sidebar-container .cbox-"+i).colorbox({innerWidth:510, innerHeight:150, html: String($('.cbox-'+i+' .cbox-content').html()).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')});
	}

    $('.nav-container-inner ul ul a').each(function() {
        $(this).removeClass('nav-item');
        $(this).addClass('menu-link');
    });
    $(".nav-container-inner .sub-menu").parent().append("<div class='arrow'></div>");

     $('.sub-menu1').css('top', '50px');
     $('div.mp-level').each(function() {
       $(this).parent().addClass('icon-caret-left');
       $(this).parent().addClass('icon');
       if ($(this).attr('data-level') != 1) {
       h2All = $(this).siblings();
       h2Link = h2All.attr('href');
       h2Text = h2All.html();
       h2HTML = '<h2><a href="'+h2Link+'">'+h2Text+'</a></h2>';
      $(this).prepend(h2HTML);
       }
    });
    $("#mp-menu .mp-level").siblings().prepend('<i class="icon-nav icon-angle-left"></i>');

	var window_size = jQuery( window ).width();
	if ( 751 > window_size ) {
		jQuery( '.blog-signup' ).prependTo('.blog-signup-mobile');
	}
	jQuery( window ).on( 'resize', function() {
		window_size = jQuery(this).width();
		if ( 751 > window_size ) {
			jQuery( '.blog-signup' ).prependTo('.blog-signup-mobile');
		} else {
			jQuery( '.blog-signup' ).prependTo('.sidebar-container');
		}
	});
    $(".welcome-container p").addClass("text");
    jQuery(".feature p").addClass("text");

    // mobile-menu
    jQuery('#mobile-menu .parent-link .mm-ic').click(function(){
        jQuery(this).parents('.p-ct').siblings('ul').animate({
            left: 0+'px'
          }, 500, function() {
            // Animation complete.
        });
    });

    jQuery('#mobile-menu .close').click(function(){
        var MMwidth = jQuery('.mobile-menu').width();
        jQuery('#page').animate({
            'margin-left': 0+'px'
          }, 500, function() {
            jQuery('.mobile-menu-container').css('top', 0+'px');
        });
        jQuery('#mobile-menu').animate({
            'left': -MMwidth+'px'
          }, 500, function() {
            // Animation complete.
        });
        jQuery('.menu-trigger').removeClass('active');
    });
    jQuery('#page').click(function(){
        var MMwidth = jQuery('.mobile-menu').width();
        jQuery('#page').animate({
            'margin-left': 0+'px'
          }, 500, function() {
            jQuery('.mobile-menu-container').css('top', 0+'px');
        });
        jQuery('#mobile-menu').animate({
            'left': -MMwidth+'px'
          }, 500, function() {
            // Animation complete.
        });
        jQuery('.menu-trigger').removeClass('active');
    });
    jQuery('#mobile-menu .back').click(function(){
        var MMwidth = jQuery('.mobile-menu').width();
        jQuery(this).parents('ul').animate({
            left: MMwidth+'px'
          }, 500, function() {
            // Animation complete.
        });
    });

    jQuery('.menu-trigger').click(function(ev){
        ev.stopPropagation();
        var MMwidth = jQuery('.mobile-menu').width();
        if ( jQuery(this).hasClass('active') ) {

            jQuery('#page').animate({
                'margin-left': 0+'px'
              }, 500, function() {
                // Animation complete.
            });
            jQuery('#mobile-menu').animate({
                'left': -MMwidth+'px'
              }, 500, function() {
                jQuery('.mobile-menu-container').css('top', 0+'px');
            });
            jQuery('.menu-trigger').removeClass('active');

        } else {

            jQuery('#mobile-menu').animate({
                'left': 0+'px'
              }, 500, function() {
                // Animation complete.
            });
            jQuery('#page').animate({
                'margin-left': MMwidth+'px'
              }, 500, function() {
                // Animation complete.
            });
            jQuery('.menu-trigger').addClass('active');

        }
    });


    // social sharing
    window.setTimeout(function() {
	    jQuery('.share-page').toggle('left');
    }, 1000);
    jQuery('.share-page ul li').hover(function() {
	    jQuery(this).animate({width:'50px',marginLeft:0},500);
    }, function() {
	    jQuery(this).animate({width:'40px',marginLeft:'10px'},500);
    });

	// Moved from header.php
	jQuery('.styledrop').selectbox({
  	  	onChange: function (val, inst) {
  		  	 window.location.href = val;
  	  	}
	});

	jQuery('.lnk-search').click(function(event)
	{
		jQuery('.lnk-search').hide();
		jQuery('.div-search').show();
		jQuery('.div-search').animate(
		{
			width: '160'
		},'slow');
		event.stopPropagation();
	});

	setTimeout(function()
	{
		var height = jQuery( 'header' ).height() + jQuery( '.body-container' ).height() + jQuery( 'footer' ).height() + 20;
		jQuery('#div_first_level').height( height );
	} , 2000 );


});

/*
 function typePage() {

   // if(document.getElementById('typedrop').value){
        var condText = jQuery(this).closest(".sbSelector").html().toLowerCase();
        newCondText = condText.replace(/ /g,"-");
        //alert(newCondText);
        window.location.href = "/types/"+newCondText;

 //  setTimeout(function() {window.location.href = document.getElementById('typedrop').value;} , 500);
  //setTimeout(function() {alert(jQuery(".sbSelector").html());} , 1500);

   // }

    }
 */
