jQuery(document).ready(function($){
	
	jQuery('.need-trigger').click(function() {
	
		if ( ! jQuery(this).next('.content').hasClass('activeNeed') ) {
			//jQuery('.activeNeed').slideUp(800).removeClass('activeNeed');
			jQuery(this).next('.content').slideDown(800).addClass('activeNeed');
			jQuery(this).addClass('activeNeedTitle');
			jQuery(this).find('i').removeClass('icon-chevron-down').addClass('icon-chevron-up');
		} else {
			jQuery(this).next('.content').slideUp(800).removeClass('activeNeed');
			jQuery(this).removeClass('activeNeedTitle');
			jQuery(this).find('i').removeClass('icon-chevron-up').addClass('icon-chevron-down');
		}
			
	});
	
});